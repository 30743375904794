import { Directive, Input, ComponentFactoryResolver, ViewContainerRef, SimpleChanges, OnChanges, ComponentRef, Renderer2, SkipSelf } from '@angular/core';
import { AmpSpinnerComponent } from '@app/shared/common/amp-spinner/amp-spinner.component';
import { Size } from 'ngx-spinner';
// import { AmpSpinnerComponent, Size } from '@shared/common/amp-spinner/amp-spinner.component';

@Directive({
    selector: "[busyIf]"
})
export class BusyIfDirective implements OnChanges {

    @Input() busyIf: boolean;
    @Input() global = false;
    @Input() loading: boolean;
    @Input() text: string;
    @Input() size: Size = "medium";
    @Input() customSize: number;
    @Input() debounceTime: number;
    @Input() latenessText: string;
    @Input() useMinHeight: boolean = true;

    spinnerRef: ComponentRef<AmpSpinnerComponent>;
    /**
     * The position of the parent element. Save this to restore it when the spinner is destroyed
     */
    parentPosition: string;
    /**
     * If the parent element had a position, reposition it to that position when the spinner is destroyed
     */
    repositionAtEnd: boolean;


    constructor(
        @SkipSelf() private  _parentConatiner: ViewContainerRef,
        private _viewContainer: ViewContainerRef,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private renderer: Renderer2,
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['loading']) {
            const currentLoading = changes.loading.currentValue;
            if (currentLoading) {
                this.createAndAttachSpinner();
            } else {
                this.destroySpinner();
            }
        }
    }

    private createAndAttachSpinner(): void {
        const el = this._viewContainer.element.nativeElement
        const parent = el.parentNode

        this.parentPosition = parent.style?.position || ''
        if(this.parentPosition && this.parentPosition !== 'relative'){
            this.repositionAtEnd = true
        }

        this.renderer.setStyle(parent,'position','relative')
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(
            AmpSpinnerComponent
        );
        this.spinnerRef = this._viewContainer.createComponent(componentFactory);
        this.spinnerRef.instance.text = this.text;
        this.spinnerRef.instance.global = this.global;
        // this.spinnerRef.instance.size = this.size;
        this.spinnerRef.instance.customSize = this.customSize;
        this.spinnerRef.instance.debounceTime = this.debounceTime;
        this.spinnerRef.instance.latenessText = this.latenessText;
        this.spinnerRef.instance.useMinHeight = this.useMinHeight;
    }



    private destroySpinner(): void {
        if (this.spinnerRef) {
        this.spinnerRef.destroy();
        }
        if (this._parentConatiner.element.nativeElement.style){
            this._parentConatiner.element.nativeElement.style.position = this.parentPosition
        }
    }

    ngOnDestroy(): void {
        this.destroySpinner();
    }
}
