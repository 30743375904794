import { NgModule } from "@angular/core";
import { ButtonBusyDirective } from "./button-busy.directive";
import { AppBsModalDirective } from "@shared/common/appBsModal/app-bs-modal.directive";
import { LuxonFromNowPipe } from "./luxon-from-now.pipe";
import { PasswordComplexityValidator } from "./validation/password-complexity-validator.directive";
import { DatePickerLuxonModifierDirective } from "./date-time/date-picker-luxon-modifier.directive";
import { BusyIfDirective } from "./busy-if.directive";
import { DateRangePickerLuxonModifierDirective } from "@app/shared/directives/date-range-picker-luxon-modifier.directive";

@NgModule({
    declarations: [
        ButtonBusyDirective,
        BusyIfDirective,
        AppBsModalDirective,
        DatePickerLuxonModifierDirective,
        LuxonFromNowPipe,
        PasswordComplexityValidator,
    ],
    imports:[
        DateRangePickerLuxonModifierDirective,
    ],
    exports: [
        ButtonBusyDirective,
        BusyIfDirective,
        AppBsModalDirective,
        DatePickerLuxonModifierDirective,
        LuxonFromNowPipe,
        PasswordComplexityValidator,
        DateRangePickerLuxonModifierDirective,
    ],
})

export class DirectivesModule {}
