import { Component, Injector, OnInit } from "@angular/core";
import { Subscription, timer } from "rxjs";
import { AppConsts } from "@shared/AppConsts";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import { AMPCommonModule } from "@shared/common/common.module";
import { AppComponentBase } from "@shared/common/app-component-base";
import { APIConfigurationServiceProxy } from "@shared/service-proxies/service-proxies";

@Component({
    selector: "amp-new-version",
    templateUrl: "./amp-new-version.component.html",
    styleUrls: ["./amp-new-version.component.css"],
    standalone: true,
    imports: [
        AMPCommonModule,

    ]
})
export class AmpNewVersionComponent extends AppComponentBase implements OnInit {
    refreshing: Boolean =  false;
    timerSub: Subscription;
    showAlert = false;
    remoteVersion = null;

    constructor(
        public injector: Injector,
        private _apiCongifProxy: APIConfigurationServiceProxy,
        // private _changeLogProxy: ChangeLogServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        const localStorage = new LocalStorageService();
        this.timerSub = timer(10000, AppConsts.CheckVersionInterval).subscribe(() => {
            this._apiCongifProxy.checkVersionUpdate()
            .subscribe((response) => {
                const remoteVersion = response;
                localStorage.getItem("webAppVersion", (err, value) => {
                    if (value != remoteVersion) {
                        localStorage.setItem("webAppVersion", remoteVersion);

                        this.remoteVersion = remoteVersion;
                        this.showAlert = true;
                    }
                });
            });
            // this._changeLogProxy.getLastChangeLogId()
            // .subscribe(res => {
            //     localStorage.getItem("showChangeLogModal", (err, value) =>
            //     {
            //         if (!value ) return
            //         if(value.id == undefined){
            //             localStorage.setItem("showChangeLogModal", {show: false, id: res});
            //         }
            //         else if(value.id != res){
            //             localStorage.setItem("showChangeLogModal", {show: true, id: res});
            //         }
            //     });

            // })
        });

    }

    refresh() {
        this.refreshing = true;
        window.location.href =
            window.location.href + "?v=" + this.remoteVersion;
    }

    ngOnDestroy(): void {
        this.timerSub?.unsubscribe();
    }
}
