import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'flaticon-line-graph',
                '/app/admin/hostDashboard'
            ),

            new AppMenuItem('Growth', 'Pages.Growth.Tenant', 'fas fa-chart-line', '', [], [
                new AppMenuItem('Agencies', 'Pages.Growth.Tenant.Agencies', 'flaticon-layer', '/app/growth/agencies'),
                new AppMenuItem('Campaigns', 'Pages.Growth.Tenant.Campaign', 'fas fa-broadcast-tower', '/app/growth/campaigns'),
                new AppMenuItem('Channels', 'Pages.Growth.Tenant.Channels', 'flaticon-squares', '/app/growth/channels')
            ]),

            new AppMenuItem('BizDev', 'Pages.BizDev.Tenant', 'flaticon-layer', '', [], [
                    new AppMenuItem('Vendors', 'Pages.BizDev.Tenant.Vendors', 'fas fa-user-tie', '/app/biz-dev/vendors'),
                    new AppMenuItem('Services', 'Pages.BizDev.Tenant.Service', 'flaticon-layer', '/app/biz-dev/services'),
                ]
            ),

            new AppMenuItem('Insights', 'Pages.Insights.Tenant', 'flaticon2-line-chart', '/app/insights/', [], [
                new AppMenuItem('ProductKPIS', 'Pages.Insights.Tenant.ProductKpi', 'flaticon2-infographic', '/app/insights/product-kpi'),
                new AppMenuItem('DailyProductKPIS', 'Pages.Insights.Tenant.ProductKpi', 'flaticon2-infographic', '/app/insights/daily-product-kpi'),
                new AppMenuItem('AdSpend', 'Pages.Insights.Tenant.ACQCostKpi', 'flaticon2-infographic', '/app/insights/ad-spend'),
                new AppMenuItem('ROI', 'Pages.Insights.Tenant.ROI', 'flaticon2-infographic', '/app/insights/ROI'),
                new AppMenuItem('LicenseCosts', 'Pages.Insights.Tenant.LicenseCosts', 'flaticon2-infographic', '/app/insights/license-cost'),
            ]),

            new AppMenuItem('Product', 'Pages.Product.Tenant', 'flaticon-doc', '/app/product/', [], [
                new AppMenuItem('Products', 'Pages.Product.Tenant.Products', 'flaticon-clock', '/app/product/products'),
                new AppMenuItem('LicenseProviders', 'Pages.Product.Tenant.License.Providers', 'flaticon2-document', '/app/product/license-providers'),
                new AppMenuItem('Licenses', 'Pages.Product.Tenant.Licenses', 'flaticon2-document', '/app/product/licenses'),
            ]),

            new AppMenuItem('Finance', 'Pages.Finance.Tenant', 'flaticon2-cardiogram', '/app/finance/', [], [
                new AppMenuItem('Exchange Rates', 'Pages.Finance.Tenant.ChangeRate', 'flaticon-coins', '/app/finance/exchange-rates'),
            ]),
            new AppMenuItem('CRM', 'Pages.Tenant.CRM', 'fas fa-handshake', '/app/crm'),
            // new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem(
                'Administration',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Administration.OrganizationUnits',
                        'flaticon-map',
                        '/app/admin/organization-units'
                    ),
                    new AppMenuItem('BusinessUnits', 'Pages.Administration.BusinessUnits', 'fas fa-sitemap', '/app/admin/business-units'),
                    new AppMenuItem('VendorTypes', 'Pages.Administration.VendorTypes', 'fas fa-user-tie', '/app/admin/vendor-types'),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'flaticon-tabs',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        'flaticon-folder-1',
                        '/app/admin/auditLogs'
                    ),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'flaticon-lock',
                        '/app/admin/maintenance'
                    ),
                    // new AppMenuItem(
                    //     'Subscription',
                    //     'Pages.Administration.Tenant.SubscriptionManagement',
                    //     'flaticon-refresh',
                    //     '/app/admin/subscription-management'
                    // ),
                    new AppMenuItem(
                        'VisualSettings',
                        'Pages.Administration.UiCustomization',
                        'flaticon-medical',
                        '/app/admin/ui-customization'
                    ),
                    // new AppMenuItem(
                    //     'WebhookSubscriptions',
                    //     'Pages.Administration.WebhookSubscription',
                    //     'flaticon2-world',
                    //     '/app/admin/webhook-subscriptions'
                    // ),
                    new AppMenuItem(
                        'DynamicProperties',
                        'Pages.Administration.DynamicProperties',
                        'flaticon-interface-8',
                        '/app/admin/dynamic-property'
                    ),

                    new AppMenuItem('AcquisitionFlows', 'Pages.Administration.Acquisition.Flows', 'flaticon-web', '/app/admin/acquisition-flows'),
                    new AppMenuItem('AcquisitionFlowTemplates', 'Pages.Administration.Acquisition.Flow.Templates', 'flaticon-web', '/app/admin/acquisition-flow-templates'),
                    new AppMenuItem('AccessFlows', 'Pages.Administration.Access.Flows', 'flaticon-web', '/app/admin/access-flows'),
                    new AppMenuItem('MessagesType', 'Pages.Administration.Messages.Types', 'fas fa-envelope', '/app/admin/messages-types'),
                    new AppMenuItem('Variables', 'Pages.Administration.Variables', 'fas fa-code', '/app/admin/variables'),
                    new AppMenuItem('ProvisionerPlatforms', 'Pages.Administration.Platform.List', 'flaticon-layer', '/app/admin/provisioners-platforms'),
                    new AppMenuItem('Currencies', 'Pages.Administration.Currencies', 'flaticon-coins', '/app/admin/currencies'),
                    new AppMenuItem('ChangeLogs', 'Pages.Administration.Changelog', 'fa fa-history', '/app/admin/change-logs'),

                    // new AppMenuItem(
                    //     'Notifications',
                    //     '',
                    //     'flaticon-alarm',
                    //     '',
                    //     [],
                    //     [
                    //         new AppMenuItem(
                    //             'Inbox',
                    //             '',
                    //             'flaticon-mail-1',
                    //             '/app/notifications'
                    //         ),
                    //         new AppMenuItem(
                    //             'MassNotifications',
                    //             'Pages.Administration.MassNotification',
                    //             'flaticon-paper-plane',
                    //             '/app/admin/mass-notifications'
                    //         )
                    //     ]
                    // ),
                    // new AppMenuItem(
                    //     'Settings',
                    //     'Pages.Administration.Host.Settings',
                    //     'flaticon-settings',
                    //     '/app/admin/hostSettings'
                    // ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'flaticon-settings',
                        '/app/admin/tenantSettings'
                    )
                ]
            ),
            // new AppMenuItem(
            //     'DemoUiComponents',
            //     'Pages.DemoUiComponents',
            //     'flaticon-shapes',
            //     '/app/admin/demo-ui-components'
            // ),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                if (!subMenuItem.hasFeatureDependency()) {
                    return true;
                }

                if (subMenuItem.featureDependencySatisfied()) {
                    return true;
                }
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
