import { Directive, Self, Output, EventEmitter, Input, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DateTime } from 'luxon';
import compare from 'just-compare';

@Directive({
  selector: '[dateRangePickerLuxonModifier]',
  standalone: true,
  providers: [ BsDaterangepickerDirective ]
})
export class DateRangePickerLuxonModifierDirective  implements OnDestroy, OnChanges {
  @Input() date = new EventEmitter();
  @Output() dateChange = new EventEmitter();

  subscribe: Subscription;
  lastDates: Date[] = null;

  constructor(@Self() private bsDateRangepicker: BsDaterangepickerDirective) {
      this.subscribe = bsDateRangepicker.bsValueChange
          .pipe(filter(dates => !!(dates && dates[0] instanceof Date && dates[1] instanceof Date
              && !compare(this.lastDates, dates) && dates[0].toString() !== 'Invalid Date' && dates[1].toString() !== 'Invalid Date')))
          .subscribe((dates: Date[]) => {
              this.lastDates = dates;
              let startDate = DateTime.fromJSDate(dates[0]);
              let endDate = DateTime.fromJSDate(dates[1]);
              this.dateChange.emit([startDate, endDate]);
          });
  }

  ngOnDestroy() {
      this.subscribe.unsubscribe();
  }

  ngOnChanges({ date }: SimpleChanges) {
      if (date && date.currentValue && !compare(date.currentValue, date.previousValue)) {
          setTimeout(() => this.bsDateRangepicker.bsValue = [new Date(date.currentValue[0]), new Date(date.currentValue[1])], 0);
      }
  }
}
