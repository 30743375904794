import { NgModule } from "@angular/core";
import { CreateNewUserDelegationModalComponent } from "./create-new-user-delegation-modal.component";
import { LinkedAccountsModalComponent } from "./linked-accounts-modal.component";
import { UserDelegationsModalComponent } from "./user-delegations-modal.component";
import { AMPCommonModule } from "@shared/common/common.module";
import { TableModule } from "primeng/table";
import { PaginatorModule } from "primeng/paginator";
import { LinkAccountModalComponent } from "./link-account-modal.component";
import { ValidationMessagesComponent } from "@shared/utils/validation-messages.component";
import { CommonLookupModalComponent } from "../common/lookup/common-lookup-modal.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

@NgModule({
    declarations: [
        LinkedAccountsModalComponent,
        UserDelegationsModalComponent,
        CreateNewUserDelegationModalComponent,
        LinkAccountModalComponent,
    ],
    imports: [
        AMPCommonModule,
        TableModule,
        PaginatorModule,
        ValidationMessagesComponent,
        CommonLookupModalComponent,
        BsDatepickerModule,
    ],
    exports: [
        LinkedAccountsModalComponent,
        UserDelegationsModalComponent
    ]
})
export class LayoutModule {}
