import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        // AMP PROXIES
        ApiServiceProxies.ServiceServiceProxy,
        ApiServiceProxies.AccessControlFlowServiceProxy,
        ApiServiceProxies.ServiceAccessControlFlowServiceProxy,
        ApiServiceProxies.AccessControlPreviewServiceProxy,
        ApiServiceProxies.AccessControlOperationServiceProxy,
        ApiServiceProxies.ProvisionerServiceProxy,
        ApiServiceProxies.ProductServiceProxy,
        ApiServiceProxies.ProductCategoryServiceProxy,
        ApiServiceProxies.AggregatorServiceProxy,
        ApiServiceProxies.ServiceServiceProxy,
        ApiServiceProxies.AgencyServiceProxy,
        ApiServiceProxies.AgencyContactServiceProxy,
        ApiServiceProxies.AggregatorContactServiceProxy,
        ApiServiceProxies.ProductContactServiceProxy,
        ApiServiceProxies.VendorContactServiceProxy,
        ApiServiceProxies.AccessControlPreviewServiceProxy,
        ApiServiceProxies.ProvisionerContactServiceProxy,

        ApiServiceProxies.EntityContactsServiceProxy,
        ApiServiceProxies.ChannelServiceProxy,
        ApiServiceProxies.CampaignServiceProxy,
        ApiServiceProxies.VendorServiceProxy,
        ApiServiceProxies.AcquisitionFlowServiceProxy,
        ApiServiceProxies.AcquisitionOperationServiceProxy,
        ApiServiceProxies.AcquisitionPreviewServiceProxy,
        ApiServiceProxies.AcquisitionFlowTemplateServiceProxy,
        ApiServiceProxies.ServiceAquisitionFlowServiceProxy,
        ApiServiceProxies.VendorNetworkServiceProxy,
        ApiServiceProxies.TermServiceProxy,
        ApiServiceProxies.PricingModelServiceProxy,
        ApiServiceProxies.PricePointServiceProxy,
        ApiServiceProxies.VendorPricePointServiceProxy,
        ApiServiceProxies.ServicePricingModelServiceProxy,
        ApiServiceProxies.ShortNumberServiceProxy,
        ApiServiceProxies.MessageServiceProxy,
        ApiServiceProxies.VariableServiceProxy,
        ApiServiceProxies.MessageTypeServiceProxy,
        ApiServiceProxies.CommonEntityContactServiceProxy,
        ApiServiceProxies.BillingStrategyServiceProxy,
        ApiServiceProxies.RevenueModelServiceProxy,
        ApiServiceProxies.ProvisionerPlatformServiceProxy,
        ApiServiceProxies.FinanceServiceProxy,
        ApiServiceProxies.MessagingModelServiceProxy,
        ApiServiceProxies.ProductProviderServiceProxy,
        ApiServiceProxies.VendorProductProviderServiceProxy,
        // ApiServiceProxies.ReportBugServiceProxy,
        // ApiServiceProxies.ChangeLogServiceProxy,

        ApiServiceProxies.LicenseServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.LicenseProviderServiceProxy,
        ApiServiceProxies.AcquisitionFlowTemplateServiceProxy,
        ApiServiceProxies.CurrencyServiceProxy,

        ApiServiceProxies.ChannelTypeServiceProxy,
        ApiServiceProxies.BlockingNetworkGroupServiceProxy,
        ApiServiceProxies.BlockingNetworkServiceProxy,
        ApiServiceProxies.CampaignBlockingNetworkGroupServiceProxy,

        ApiServiceProxies.CrmServiceProxy,

        ApiServiceProxies.BusinessUnitServiceProxy,
        ApiServiceProxies.VendorTypeServiceProxy,

        ApiServiceProxies.LicenseProductVendorServiceProxy,

        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
