<div
    #modal="bs-modal"
    [config]="{ backdrop: 'static' }"
    appBsModal
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <span>{{ 'YourSessionIsAboutToExpire' | localize }}</span>
                </h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true"></button>
            </div>
            <div class="modal-body">
                <p>{{ l('SessionExpireRedirectingInXSecond', currentSecond) }}</p>
                <div class="bg-light-primary rounded">
                    <div
                        class="bg-primary rounded text-center"
                        role="progressbar"
                        [style.width]="progresbarWidth"
                        style="min-width: 15px"
                    >
                        <span>{{ currentSecond }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
