<style>
    h5, span{
        cursor: auto;
        font-weight: 500;
        font-family: poppins;
    }
    h5{
        font-size: 1.25rem;
    }
</style>

<div [class]="currentTheme.baseSettings.subHeader.containerStyle" class="amp-subheader-component">
    <div [class]="containerClass + ' d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'">
        <div class="d-flex align-items-center flex-wrap me-2">
            <h5 class="mb-2 me-2 mt-2 text-dark" (click)="goToTitleLink()" [ngStyle]="{'cursor':isLinkTitle() ? 'pointer' : 'auto' }">{{title}}</h5>
            <div *ngIf="description" class='subheader-separator subheader-separator-ver mt-2 mb-2 mx-3 bg-gray-200'></div>
            <div *ngIf="description" class="d-flex align-items-center class-with-bold">
                <span class='text-muted font-weight-bold me-4'>{{description}}</span>
            </div>
            <div *ngIf="breadcrumbs && breadcrumbs.length > 0" class='subheader-separator subheader-separator-ver mt-2 mb-2 me-4 bg-gray-200'></div>
            <ul *ngIf="breadcrumbs && breadcrumbs.length > 0"
                class='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 mx-n2 font-size-sm'>
                <li class='breadcrumb-item' *ngFor="let breadcrumbItem of breadcrumbs">
                    <a *ngIf="breadcrumbItem.isLink()" style="cursor:pointer" (click)="goToBreadcrumb(breadcrumbItem)" class='text-muted'>
                        {{breadcrumbItem.text}}
                    </a>
                    <span *ngIf="!breadcrumbItem.isLink()" class='text-muted'>{{breadcrumbItem.text}}</span>
                </li>
            </ul>

        </div>
        <div class="d-flex align-items-center">
            <ng-content select="div[role=actions]"></ng-content>
        </div>
    </div>
</div>
