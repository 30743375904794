import { NgModule } from "@angular/core";
import { AMPCommonModule } from "@shared/common/common.module";
import { MenuSearchBarComponent } from "./menu-search-bar/menu-search-bar.component";
import { SideBarMenuComponent } from "./side-bar-menu.component";
import { TopBarMenuComponent } from "./top-bar-menu.component";
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RouterModule } from "@angular/router";

@NgModule({
    imports:[
        AMPCommonModule,
        AutoCompleteModule,
        RouterModule
    ],
    declarations:[
        MenuSearchBarComponent,
        SideBarMenuComponent,
        TopBarMenuComponent
    ],
    exports:[
        TopBarMenuComponent,
        SideBarMenuComponent
    ]
})
export class NavModule {}
