<mat-dialog-content>
    <div>
        <div class="flex-row-fluid">
            <div class="card card-custom">
                <div class="card-body">
                    <div>
                        <div>
                            <div style="display:flex; flex-direction: row; align-items: flex-start;justify-content: space-between;">
                                <h4 style="width: 50%; font-size: small;">
                                    {{"MonthlyAverage" | localize}} <br>
                                    {{firstCurrency}}->{{secondCurrency}}: <br>
                                    {{monthAverage}}
                                </h4>
                                <datePicker-component #prueba style="width: 50%;"
                                    [type]="dateType"
                                    [granularity]="dateGranularity"
                                    [end]="date"
                                    (singleDateChange)="selectDate($event)">
                                </datePicker-component>
                            </div>
                        </div>
                        <div>
                            <div style="display:flex; flex-direction: row; align-items: flex-start;justify-content: space-between;">
                                <mat-form-field #input1 appearance="fill" style="width: 50%;">
                                    <mat-label style="font-size: small;">{{firstCurrency}}</mat-label>
                                    <input matInput style="font-size: larger;" type="number" (input)="firstInputEvent()">
                                </mat-form-field>
                                <button mat-button [matMenuTriggerFor]="menu1" style="font-size: medium; width: 50%; height: 55px;" class="mat-focus-indicator mat-menu-trigger mat-button mat-button-base">{{menu1Name}} ({{firstCurrency}})</button>
                                <mat-menu #menu1="matMenu">
                                    <button mat-menu-item *ngFor="let moneda of (monedas | async)" (click)="changeFirstCurrency(moneda.currencyId)">{{moneda.currency}} ({{moneda.currencyId}})</button>
                                </mat-menu>
                            </div>
                            <div style="display:flex; flex-direction: row; align-items: flex-start;justify-content: space-between;">
                                <mat-form-field #input2 appearance="fill" style="width: 50%;">
                                    <mat-label style="font-size: small;">{{secondCurrency}}</mat-label>
                                    <input matInput style="font-size: larger;" type="number" (input)="secondInputEvent()">
                                </mat-form-field>
                                <button mat-button [matMenuTriggerFor]="menu2" style="font-size: medium; width: 50%; height: 55px;" class="mat-focus-indicator mat-menu-trigger mat-button mat-button-base">{{menu2Name}} ({{secondCurrency}})</button>
                                <mat-menu #menu2="matMenu">
                                    <button mat-menu-item *ngFor="let moneda of (monedas | async)" (click)="changeSecondCurrency(moneda.currencyId)">{{moneda.currency}} ({{moneda.currencyId}})</button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</mat-dialog-content>
