import { Component } from '@angular/core';
import { AmpGlobalSpinnerService } from '@app/shared/services/amp-global-spinner.service'//'./shared/amp-global-spinner.service';


@Component({
    selector: 'app-root',
    template: `
        <amp-new-version></amp-new-version>
        <router-outlet></router-outlet>
        <!-- <ng-container *ngIf="ampSpinnerService.customIsActive$ | async">
            <amp-global-spinner></amp-global-spinner>
        </ng-container> -->
        <!-- <ng-container *ngIf="ampSpinnerService.normalIsActive$ | async">
            <amp-spinner [global]="true" [text]="ampSpinnerService.normalTextSpinner$ | async| localize"></amp-spinner>
        </ng-container> -->
        <!--router-outlet></router-outlet>
        <ngx-spinner type="ball-clip-rotate" size="medium" color="#5ba7ea">
            <p *ngIf="ngxSpinnerText">{{ getSpinnerText() }}</p>
        </ngx-spinner-->
    `
})
export class RootComponent {
    constructor(public ampSpinnerService: AmpGlobalSpinnerService) { }
}
