import { Component, Injector, OnInit, Input } from '@angular/core';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';
import { AbpSessionService } from 'abp-ng2-module';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { CalculatorModalComponent } from '../calculator/calculator.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'calculator-toggle-button',
    templateUrl: './calculator-toggle-button.component.html'
})
export class CalculatorToggleButtonComponent extends ThemesLayoutBaseComponent implements OnInit {

    isHost = false;

    @Input() customStyle = 'btn btn-icon btn-dropdown btn-clean btn-lg mr-1';

    public constructor(
        injector: Injector,
        private _abpSessionService: AbpSessionService,
        _dateTimeService: DateTimeService,
        private dialog: MatDialog
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit(): void {
        this.isHost = !this._abpSessionService.tenantId;
    }

    calculator(){
        const dialogRef = this.dialog.open(CalculatorModalComponent, {
            width: '700px',
        });
    }
}
