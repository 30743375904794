<div
    #linkedAccountsModal="bs-modal"
    appBsModal
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
    [config]="{ keyboard: false }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{ 'LinkedAccounts' | localize }}</span>
                </h4>
                <button class="btn btn-primary pull-right" (click)="manageLinkedAccounts()">
                    <i class="fa fa-plus btn-md-icon"></i>
                    <span class="d-none d-md-inline-block">
                        {{ 'LinkNewAccount' | localize }}
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <!--<Primeng-TurboTable-Start>-->
                <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                    <p-table
                        #dataTable
                        sortMode="multiple"
                        (onLazyLoad)="getLinkedUsers($event)"
                        [value]="primengTableHelper.records"
                        rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                        [paginator]="false"
                        [lazy]="true"
                        [tableStyle]="{'min-width': '25rem'}"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 20%">
                                    {{ 'Actions' | localize }}
                                </th>
                                <th style="width: 65%" pSortableColumn="userName">
                                    {{ 'UserName' | localize }}
                                    <p-sortIcon field="userName"></p-sortIcon>
                                </th>
                                <th style="width: 15%">
                                    {{ 'Delete' | localize }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-record="$implicit">
                            <tr>
                                <td style="width: 20%">
                                    <button class="btn btn-sm btn-primary" (click)="switchToUser(record)">
                                        <i class="fa fa-sign-in-alt"></i>
                                        {{ 'LogIn' | localize }}
                                    </button>
                                </td>
                                <td style="width: 65%">
                                    <span class="p-column-title">{{ 'UserName' | localize }}</span>
                                    {{ getShownLinkedUserName(record) }}
                                </td>
                                <td style="width: 15%">
                                    <button
                                        class="btn btn-outline-danger btn-sm btn-icon"
                                        (click)="deleteLinkedUser(record)"
                                    >
                                        <i class="fa fa-trash" [attr.aria-label]="l('Delete')"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                        {{ 'NoData' | localize }}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator
                            [rows]="primengTableHelper.defaultRecordsCountPerPage"
                            #paginator
                            (onPageChange)="getLinkedUsers($event)"
                            [totalRecords]="primengTableHelper.totalRecordsCount"
                            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                            [showCurrentPageReport]="true"
                            [currentPageReportTemplate]="
                                'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                            "
                        ></p-paginator>
                    </div>
                </div>
                <!--<Primeng-TurboTable-End>-->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light-primary fw-bold pull-right" (click)="close()">
                    {{ 'Close' | localize }}
                </button>
            </div>
        </div>
    </div>
</div>
<linkAccountModal #linkAccountModal (modalSave)="getLinkedUsers()"></linkAccountModal>
