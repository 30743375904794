<!--
<div id="chat_is_connecting_icon" class="topbar-item">
    <button [class]="customStyle">
        <img *ngIf="!chatConnected" src="./assets/common/images/loading.gif" style="width: 23px;" tooltip="{{'ChatIsConnecting' | localize}}" placement="left"/>
    </button>
</div> -->

<div id="pt_quick_sidebar_toggle" class="topbar-item" style="height: 100%;">
    <button [class]="customStyle" (click)="calculator()">
        <div id="calculatorIcon" >
            <i class="flaticon-coins"></i>
        </div>
    </button>
</div>
