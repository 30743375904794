import { NgModule } from "@angular/core";
import { DefaultBrandComponent } from "./default/default-brand.component";
import { DefaultLayoutComponent } from "./default/default-layout.component";
import { DefaultLogoComponent } from "./default/default-logo.component";
import { RouterModule } from "@angular/router";
import { NavModule } from "../nav/nav.module";
import { TopBarModule } from "../topbar/top-bar.module";
import { HeaderNotificationsComponent } from "../notifications/header-notifications.component";
import { ToggleDarkModeComponent } from "../toggle-dark-mode/toggle-dark-mode.component";
// import { FooterComponent } from "../footer.component";
import { AMPCommonModule } from "@shared/common/common.module";

 @NgModule({
    declarations: [
        DefaultBrandComponent,
        DefaultLayoutComponent,
        DefaultLogoComponent,
    ],
    imports:[
        AMPCommonModule,
        RouterModule,
        NavModule,
        TopBarModule,
        HeaderNotificationsComponent,
        ToggleDarkModeComponent,
        // FooterComponent
    ],
    exports: [
        DefaultLayoutComponent,
        TopBarModule
    ]
 })
 export class ThemeModule {}
