import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChatSignalrService } from '@app/shared/layout/chat/chat-signalr.service';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { ImpersonationService } from './admin/users/impersonation.service';
import { AppComponent } from './app.component';
import { ThemeSelectionPanelComponent } from './shared/layout/theme-selection/theme-selection-panel.component';
import { LinkedAccountService } from './shared/layout/linked-account.service';
import { UserNotificationHelper } from './shared/layout/notifications/UserNotificationHelper';
import { SessionTimeoutComponent } from './shared/common/session-timeout/session-timeout.component';
import { ScrollTopComponent } from './shared/layout/scroll-top.component';

import { RefreshTokenInterceptor } from './shared/refresh-interceptor';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { APIConfigurationServiceProxy, FinanceServiceProxy } from '@shared/service-proxies/service-proxies';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { AppAuthService } from './shared/common/auth/app-auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ChangeProfilePictureModalComponent } from './shared/layout/profile/change-profile-picture-modal.component';
import { ThemeModule } from './shared/layout/themes/theme.module';
import { NotificationSettingsModalComponent } from './shared/layout/notifications/notification-settings-modal.component';
import { LayoutModule } from './shared/layout/layout.module';
import { ChatModule } from './shared/layout/chat/chat.module';
import { DateTimeService } from './shared/common/timing/date-time.service';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import { OffcanvasDirective } from '../assets/metronic/app/kt/directives/offcanvas.directive';


@NgModule({
    declarations: [
        AppComponent,
        ScrollTopComponent,
    ],
    providers: [
        DatePipe,
        ImpersonationService,
        LinkedAccountService,
        UserNotificationHelper,
        ChatSignalrService,
        FinanceServiceProxy,
        NgxSpinnerService,
        DateTimeService,
        LocalStorageService,
        APIConfigurationServiceProxy,
        AppAuthService,
        AppRouteGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
        OffcanvasDirective,
    ],
    imports: [
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        ThemeSelectionPanelComponent,
        ChangeProfilePictureModalComponent,
        MySettingsModalComponent,
        ThemeModule,
        ChangePasswordModalComponent,
        NotificationSettingsModalComponent,
        LayoutModule,
        SessionTimeoutComponent,
    ]
})
export class AppModule { }

function appInitializerFactory() {
    return () => {

        const url = new URL(location.href);
        const params = url.searchParams;

        if (params.has('t')) {
            params.delete('t');
            window.location.href = url.toString();
        }
    };
}
