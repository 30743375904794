import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PipesModule } from './pipes/pipes.module';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@shared/utils/directives.module';

@NgModule({
    imports: [
        PipesModule,
        DirectivesModule
     ],
    exports: [
        CommonModule,
        FormsModule,
        PipesModule,
        DirectivesModule
    ]
})

export class AMPCommonModule { }
