import { NgModule } from "@angular/core";
import { LocalizePipe } from "./localize.pipe";
import { PermissionAllPipe } from "./permission-all.pipe";
import { FeatureCheckerPipe } from "./feature-checker.pipe";
import { PermissionAnyPipe } from "./permission-any.pipe";
import { PermissionPipe } from "./permission.pipe";
import { LuxonFormatPipe } from "@shared/utils/luxon-format.pipe";

const PIPES = [
    LocalizePipe,
    PermissionAllPipe,
    PermissionPipe,
    PermissionAnyPipe,
    FeatureCheckerPipe,
    LuxonFormatPipe
]

@NgModule({
    declarations: PIPES,
    exports: PIPES
})
export class PipesModule{}
7
