import { environment } from '../../environments/environment';
import { SettingService } from 'abp-ng2-module';
import { Injector } from '@angular/core';

export interface IAmpURLEnviromen {
    generalbaseCdn : string,
    bucket : string,
    access : string,
    acquisition : string,
    acqVas: string,
    acqEngine : string,
    bucketAcq : string
}

export class AmpBaseUrls {
    
    setting: SettingService;
    urls:any;
    constructor() {
        
        this.urls = this.getAmpBaseUrlsForEnviroment();
    }

   
    getAmpBaseUrlsForEnviroment = ():IAmpURLEnviromen => {
        let env = environment;
        let currentEnviroment:string = env.production ? '' : 'Staging'
        let setting = new SettingService();
        let urls = {
            generalbaseCdn : setting.get(`App.General.BaseCdn${currentEnviroment}`),
            bucket : setting.get(`App.General.BaseS3Bucket${currentEnviroment}`),
            access : setting.get(`App.AccessControl.BaseCdn${currentEnviroment}`),
            acquisition : setting.get(`App.AcquisitionFlow.BaseCdn${currentEnviroment}`),
            acqVas: setting.get(`App.AcquisitionFlow.BaseAcqVas${currentEnviroment}`),
            acqEngine : setting.get(`App.AcquisitionFlow.Engine${currentEnviroment}`),
            bucketAcq : setting.get(`App.AcquisitionFlow.BaseS3Bucket${currentEnviroment}`),
        }
        
        return urls;
    }

   
}
