<div class="p-9">
<div class="awg-layout-modal">
    <h1 mat-dialog-title class="title">{{'ReportBug' | localize}}</h1>
</div>
<div class="form-container" busyIf [loading]="saving" [text]="'GeneralSpinner' | localize">
    <form [formGroup]="form" class="service-form">
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label>{{ l('Matter') }}</mat-label>
                <input
                    matInput
                    required
                    [placeholder]="l('Matter')"
                    type="text"
                    id="matter"
                    formControlName="matter"
                    name="matter"
                />
                <mat-hint *ngIf="f.matter.invalid && f.matter.touched" class="error-form">
                    {{ 'FieldRequired' | localize }}
                </mat-hint>
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>{{ l('WhatWantedToDo') }}</mat-label>
                <input
                    matInput
                    required
                    [placeholder]="l('WhatWantedToDo')"
                    type="text"
                    id="whatWantedToDo"
                    formControlName="whatWantedToDo"
                    name="whatWantedToDo"
                />
                <mat-hint *ngIf="f.whatWantedToDo.invalid &&  f.whatWantedToDo.touched" class="error-form">
                    {{ 'FieldRequired' | localize }}
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="w-100">
                <mat-label> {{ l('ExpectedBehavior') }}</mat-label>
                <input
                    matInput
                    [placeholder]="l('ExpectedBehavior')"
                    type="text"
                    id="expectedBehavior"
                    formControlName="expectedBehavior"
                    name="expectedBehavior"
                />
                <mat-hint *ngIf="f.expectedBehavior.invalid && f.expectedBehavior.touched" class="error-form">
                    {{ 'FieldRequired' | localize }}
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="w-100">
                <mat-label> {{ l('Url') }} </mat-label>
                <input
                    matInput
                    [placeholder]="l('Url')"
                    type="text"
                    id="url"
                    formControlName="url"
                    name="url"
                />
                <mat-hint *ngIf="f.url.invalid && f.url.touched" class="error-form">
                    {{ 'FieldRequired' | localize }}
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light-primary font-weight-bold" (click)="close()">{{"Cancel" | localize}}</button>
            <button type="button" (click)="save()" class="btn btn-primary font-weight-bold" [disabled]="saving"><i class="fas fa-paper-plane" ></i> <span>{{"Send" | localize}}</span></button>
        </div>
    </form>

</div>
</div>
