<div class="d-flex justify-content-between align-items-center">
    <h1 mat-dialog-title>Changelogs</h1>
    <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
</div>
<hr>
<div busyIf [loading]="showLoading" [text]="'GeneralSpinner' | localize"
class="form-container row" style="max-height: 75vh; overflow-y: auto;" (scroll)="onScroll($event)">
    <div class="col-md-12 mb-10" *ngFor="let item of changesList" >
        <div class="col-md-12">
            <p class="badge badge-primary">{{ l('Version') }} {{ item.version }}</p>
        </div>
        <div class="col-md-12">
            <markdown [data]="item.changes"></markdown>
        </div>
    </div>
</div>
