import { NgModule } from "@angular/core";
import { ActiveDelegatedUsersComboComponent } from "./active-delegated-users-combo.component";
import { ChatToggleButtonComponent } from "./chat-toggle-button.component";
import { LanguageSwitchDropdownComponent } from "./language-switch-dropdown.component";
import { QuickThemeSelectionComponent } from "./quick-theme-selection.component";
import { SubscriptionNotificationBarComponent } from "./subscription-notification-bar.component";
import { UserMenuComponent } from "./user-menu.component";
import { AMPCommonModule } from "@shared/common/common.module";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CalculatorToggleButtonComponent } from './calculator-toggle-button.component';
import { ReportBugButtonComponent } from "./report-bug-button";
import { ShowChangelogModalButtonComponent } from "./show-changelog-modal-button";

// todos estos se usan en default-layout
const COMPONENTS = [
    ActiveDelegatedUsersComboComponent,
    ChatToggleButtonComponent,
    LanguageSwitchDropdownComponent,
    QuickThemeSelectionComponent,
    SubscriptionNotificationBarComponent,
    UserMenuComponent,
    CalculatorToggleButtonComponent,
    ReportBugButtonComponent,
    ShowChangelogModalButtonComponent
]

@NgModule({
    declarations: COMPONENTS,
    imports: [
        AMPCommonModule,
        TooltipModule
    ],
    exports: COMPONENTS
})
export class TopBarModule {}
